"use client";
import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useLocale, useTranslations } from 'next-intl';

const CategoryText = ({props}) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const locale = useLocale();
    const t = useTranslations("General");
    const page = props;

    const loadCategoryTextData = (locale) => {
        try {
            const context = require.context('../messages', true, /\.json$/);
            const data = context(`./${locale}/CategoryText.json`);
            return data;
        } catch (error) {
            return null;
        }
    };

    const data = loadCategoryTextData(locale);

    return (
        <>
            {data[page].title && data[page].content ? (
                <section className="flex flex-col items-center justify-center gap-4 py-4 md:py-0 mt-5">
                    <div className="container max-w-[1000px] block text-left justify-start items-center gap-3 sm:flex">
                        <FiChevronDown className="text-[30px]"/><h2 className="text-15px lg:text-[25px] font-normal text-gray-300">{ data[page].title }</h2>
                    </div>
                    <div className="max-w-[1000px] mx-auto gap-4 p-3 pb-10">
                        <div dangerouslySetInnerHTML={{ __html: data[page].content }} className={isExpanded ? "h-full category_text_content p-1 -mt-5 mb-3" : "h-[100px] overflow-hidden category_text_content p-1 -mt-5 mb-3"} />
                        <div onClick={() => setIsExpanded(!isExpanded)} className="text-blue-500 ms-1 hover:cursor-pointer">
                            {isExpanded ? t("show_less") : t("show_more")}
                        </div>
                    </div>
                </section>
            ) : (
                <></>
            )}
        </>
    )
}

export default CategoryText