"use client";

import React, { useState } from "react";
import { Card, CardBody } from "@nextui-org/react";
import Image from "next/image";
import { IoMdCloseCircle } from "react-icons/io";

import { useLocale } from 'next-intl';

export default function Cards({ props }) {
    const locale = useLocale();
    const page = props;
    const [openCard, setOpenCard] = useState(null);

    const loadServicesData = (locale) => {
        try {
            const context = require.context('../messages', true, /\.json$/);
            const data = context(`./${locale}/${page}.json`);
            return data;
        } catch (error) {
            return null;
        }
    };

    const data = loadServicesData(locale);

    const renderRankTitle = (rank, text) => {
        switch (rank) {
            case 2:
                return <h2 className="text-[15px] font-semibold text-center text-[#959595] group-hover:text-white">{text}</h2>
            case 3:
                return <h3 className="text-[15px] font-semibold text-center text-[#959595] group-hover:text-white">{text}</h3>
            case 4:
                return <h4 className="text-[15px] font-semibold text-center text-[#959595] group-hover:text-white">{text}</h4>
            default:
                return <h5 className="text-[15px] font-semibold text-center text-[#959595] group-hover:text-white">{text}</h5>;
        }
    }

    return (
        <>
            {data.Services.map((service_item, index) => (
                <div key={index} onClick={() => setOpenCard(index)}>
                    {
                        openCard === index && <div className={`z-[999] fixed top-0 left-0 w-screen h-screen place-items-center bg-black/80 p-4`}>
                            <div onClick={(e) => {
                                e.stopPropagation();
                                setOpenCard(null);
                            }} className="cursor-pointer z-[1000] float-right text-white mt-4 mr-4">
                                <IoMdCloseCircle className="size-8" />
                            </div>
                            <div className="bg-black p-4 pb-16 my-8 z-[1000] w-[min(1000px,100%)] h-full overflow-y-scroll space-y-4 *:space-y-2" dangerouslySetInnerHTML={{ __html: service_item.markdown }} />
                        </div>
                    }
                    <Card className="cursor-pointer *:!break-normal *:!justify-center bg-gradient-to-br !min-h-[120px] from-[#82828210] from-10% to-black to-80% border border-[#494949] overflow-visible group">
                        {service_item.icon ? (
                            <Image src={service_item.icon.src} width={315} height={220} alt={service_item.icon.alt} className={service_item.icon.class} quality={80} priority={false} loading="lazy" />
                        ) : null}
                        <CardBody className={`group-hover:text-white ${service_item.icon?.class?.includes("-mt-[15px]") ? "px-6 pb-6 pt-[40px]" : "p-6"}`}>
                            {renderRankTitle(service_item.rank, service_item.title)}
                        </CardBody>
                    </Card>
                </div>
            ))}
        </>
    );
}