"use client";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { useTranslations } from 'next-intl';
import Image from "next/image";
import { BsArrowRightCircleFill, BsArrowLeftCircleFill } from "react-icons/bs";

const HomePageSlider = () => {
    const m = useTranslations("HomePageCarousel");
    return (
        <Swiper
            className='!py-8 relative'
            loop
            slidesPerView={1}
            centeredSlides
            autoplay={false}
            navigation={{ enabled: true, nextEl: ".swiper-next-button", prevEl: ".swiper-prev-button" }}
            breakpoints={{
                640: {
                    enabled: true,
                    slidesPerView: 2,
                    autoplay: {
                        delay: 5000,
                        pauseOnMouseEnter: true,
                        disableOnInteraction: true
                    }
                },
                960: {
                    slidesPerView: 2.5,
                },
            }}
            modules={[Navigation, Autoplay]}
        >
            <SwiperSlide className='!grid grid-cols-1 grid-rows-2 !h-[450px] max-sm:max-h-[400px]'>
                <div className='mx-auto row-start-1 col-start-1 row-span-1 col-span-1 text-center font-bold justify-self-center self-end text-5xl max-lg:text-4xl'>
                    <p>{m("titleTop")}</p>
                    <p>
                        <span>{m("titleBottom").split(" ")[0]}</span>
                        <span className="max-xs:hidden">&nbsp;</span>
                        <span className="max-xs:block">{m("titleBottom").split(" ")[1]}</span>
                    </p>
                </div>
            </SwiperSlide>
            <SwiperSlide className='!grid grid-cols-7 grid-rows-2 max-h-[450px] max-sm:max-h-[400px] place-items-center'>
                <div className='col-start-2 grid-rows-1 row-span-1 max-xl:col-start-1 col-span-1 place-self-center font-bold text-[150px] max-md:hidden max-lg:text-9xl bg-clip-text text-transparent bg-gradient-to-br from-[#22824A] to-[#117149]'>
                    1
                </div>
                <Image alt={m("requirements_elicitation_alt")} className='object-contain max-w-[300px] max-h-[200px] max-sm:max-h-[167px] col-start-3 grid-rows-1 row-span-1 col-span-3' src="/images/planning-carousel.svg" width={600} height={200} />
                <p className='grid-rows-2 row-span-1 col-start-2 col-span-5 text-center'>
                    {m("requirements_elicitation")}
                </p>
            </SwiperSlide>
            <SwiperSlide className='!grid grid-cols-7 grid-rows-2 max-h-[450px] max-sm:max-h-[400px] place-items-center'>
                <div className='col-start-2 grid-rows-1 row-span-1 max-xl:col-start-1 col-span-1 place-self-center font-bold text-[150px] max-md:hidden max-lg:text-9xl bg-clip-text text-transparent bg-gradient-to-br from-[#22824A] to-[#117149]'>
                    2
                </div>
                <Image alt={m("design_alt")} className='object-contain max-w-[300px] max-h-[200px] max-sm:max-h-[167px] col-start-3 grid-rows-1 row-span-1 col-span-3' src="/images/ui_ux-carousel.svg" width={600} height={200} />
                <p className='grid-rows-2 row-span-1 col-start-2 col-span-5 text-center'>
                    {m("design")}
                </p>
            </SwiperSlide>
            <SwiperSlide className='!grid grid-cols-7 grid-rows-2 max-h-[450px] max-sm:max-h-[400px] place-items-center'>
                <div className='col-start-2 grid-rows-1 row-span-1 max-xl:col-start-1 col-span-1 place-self-center font-bold text-[150px] max-md:hidden max-lg:text-9xl bg-clip-text text-transparent bg-gradient-to-br from-[#22824A] to-[#117149]'>
                    3
                </div>
                <Image alt={m("content_and_seo_alt")} className='object-contain max-w-[300px] max-h-[200px] max-sm:max-h-[167px] col-start-3 grid-rows-1 row-span-1 col-span-3' src="/images/seo-carousel.svg" width={600} height={200} />
                <p className='grid-rows-2 row-span-1 col-start-2 col-span-5 text-center'>
                    {m("content_and_seo")}
                </p>
            </SwiperSlide>
            <SwiperSlide className='!grid grid-cols-7 grid-rows-2 max-h-[450px] max-sm:max-h-[400px] place-items-center'>
                <div className='col-start-2 grid-rows-1 row-span-1 max-xl:col-start-1 col-span-1 place-self-center font-bold text-[150px] max-md:hidden max-lg:text-9xl bg-clip-text text-transparent bg-gradient-to-br from-[#22824A] to-[#117149]'>
                    4
                </div>
                <Image alt={m("coding_alt")} className='object-contain max-w-[300px] max-h-[200px] max-sm:max-h-[167px] col-start-3 grid-rows-1 row-span-1 col-span-3' src="/images/coding-carousel.svg" width={600} height={200} />
                <div className='grid-rows-2 row-span-1 col-start-2 col-span-5 text-center'>
                    {m("coding")}
                </div>
            </SwiperSlide>
            <SwiperSlide className='!grid grid-cols-7 grid-rows-2 max-h-[450px] max-sm:max-h-[400px] place-items-center'>
                <div className='col-start-2 grid-rows-1 row-span-1 max-xl:col-start-1 col-span-1 place-self-center font-bold text-[150px] max-md:hidden max-lg:text-9xl bg-clip-text text-transparent bg-gradient-to-br from-[#22824A] to-[#117149]'>
                    5
                </div>
                <Image alt={m("maintenance_alt")} className='object-contain max-w-[300px] max-h-[200px] max-sm:max-h-[167px] col-start-3 grid-rows-1 row-span-1 col-span-3' src="/images/updating_and_maintenance-carousel.svg" width={600} height={200} />
                <div className='grid-rows-2 row-span-1 col-start-2 col-span-5 text-center'>
                    {m("maintenance")}
                </div>
            </SwiperSlide>
            <div className="absolute z-30 left-0 top-0 w-10 h-full grid place-items-center bg-black">
                <BsArrowLeftCircleFill className={`text-4xl cursor-pointer select-none swiper-prev-button`} />
            </div>
            <div className="absolute z-30 right-0 top-0 w-10 h-full grid place-items-center bg-black">
                <BsArrowRightCircleFill className={`text-4xl cursor-pointer select-none swiper-next-button`} />
            </div>
        </Swiper>
    );
};

export default HomePageSlider;
