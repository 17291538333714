"use client";

import { useState } from "react";
import ServiceButton from "./service_button";
import { useTranslations } from "next-intl";

export default function ServiceButtonsHandler() {
    const [selectedService, setSelectedService] = useState(null);
    const m = useTranslations("ServiceBubbles");

    return (<div className="max-lg:grid max-lg:grid-cols-2 max-lg:place-items-stretch max-lg:gap-4 max-lg:last:*:*:*:h-[120px] gap-8 lg:absolute lg:w-screen lg:h-screen lg:top-0 lg:left-0 px-4" onClick={() => setSelectedService(null)}>
        <ServiceButton className="lg:absolute lg:top-[15%] lg:left-[15%] lg:*:*:size-[150px]" contentOffset={20} isOpen={selectedService === 1} onClick={() => setSelectedService((prev) => prev === 1 ? null : 1)} onMouseOver={() => setSelectedService(1)} onMouseUp={() => setSelectedService(null)} onOpenChange={() => setSelectedService((prev) => prev === 1 ? null : 1)} title={m("strong_brand_image.title")} text={m("strong_brand_image.description")} />
        <ServiceButton className="lg:absolute lg:top-[30%] lg:left-[30%] lg:*:*:size-[125px]" contentOffset={20} isOpen={selectedService === 2} onClick={() => setSelectedService((prev) => prev === 2 ? null : 2)} onMouseOver={() => setSelectedService(2)} onMouseUp={() => setSelectedService(null)} onOpenChange={() => setSelectedService((prev) => prev === 2 ? null : 2)} title={m("sales_conversion.title")} text={m("sales_conversion.description")} />
        <ServiceButton className="lg:absolute lg:top-[15%] lg:left-[60%] lg:*:*:size-[150px]" contentOffset={20} isOpen={selectedService === 3} onClick={() => setSelectedService((prev) => prev === 3 ? null : 3)} onMouseOver={() => setSelectedService(3)} onMouseUp={() => setSelectedService(null)} onOpenChange={() => setSelectedService((prev) => prev === 3 ? null : 3)} title={m("unique_strategies.title")} text={m("unique_strategies.description")} />
        <ServiceButton className="lg:absolute lg:top-[30%] lg:left-[75%] lg:*:*:size-[150px]" contentOffset={20} isOpen={selectedService === 4} onClick={() => setSelectedService((prev) => prev === 4 ? null : 4)} onMouseOver={() => setSelectedService(4)} onMouseUp={() => setSelectedService(null)} onOpenChange={() => setSelectedService((prev) => prev === 4 ? null : 4)} title={m("trend_driven_management.title")} text={m("trend_driven_management.description")} />
        <ServiceButton className="lg:absolute lg:top-[40%] lg:left-[90%] lg:*:*:size-[150px]" contentOffset={20} isOpen={selectedService === 5} onClick={() => setSelectedService((prev) => prev === 5 ? null : 5)} onMouseOver={() => setSelectedService(5)} onMouseUp={() => setSelectedService(null)} onOpenChange={() => setSelectedService((prev) => prev === 5 ? null : 5)} title={m("content_diversity.title")} text={m("content_diversity.description")} />
        <ServiceButton className="lg:absolute lg:top-[45%] lg:left-[8%] lg:*:*:size-[150px]" contentOffset={20} isOpen={selectedService === 6} onClick={() => setSelectedService((prev) => prev === 6 ? null : 6)} onMouseOver={() => setSelectedService(6)} onMouseUp={() => setSelectedService(null)} onOpenChange={() => setSelectedService((prev) => prev === 6 ? null : 6)} title={m("data_powered_strategies.title")} text={m("data_powered_strategies.description")} />
        <ServiceButton className="lg:absolute lg:top-[62%] lg:left-[20%] lg:*:*:size-[125px]" contentOffset={20} isOpen={selectedService === 7} onClick={() => setSelectedService((prev) => prev === 7 ? null : 7)} onMouseOver={() => setSelectedService(7)} onMouseUp={() => setSelectedService(null)} onOpenChange={() => setSelectedService((prev) => prev === 7 ? null : 7)} title={m("targeted_leads.title")} text={m("targeted_leads.description")} />
        <ServiceButton className="lg:absolute lg:top-[18%] lg:left-[45%] lg:*:*:size-[115px]" contentOffset={20} isOpen={selectedService === 8} onClick={() => setSelectedService((prev) => prev === 8 ? null : 8)} onMouseOver={() => setSelectedService(8)} onMouseUp={() => setSelectedService(null)} onOpenChange={() => setSelectedService((prev) => prev === 8 ? null : 8)} title={m("global_reach.title")} text={m("global_reach.description")} />
    </div>);
}
