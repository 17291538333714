import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";

export default function ServiceButton({ onOpenChange, onClick, onMouseUp, onMouseOver, title, text, isOpen = false, className = "", contentOffset = 100 }) {

    return <div className={[className, "!z-40 cursor-pointer"].join(" ")} onMouseUp={onMouseUp} onMouseOver={onMouseOver} onClick={onClick}>
        <Popover showArrow isOpen={isOpen} offset={contentOffset} onOpenChange={onOpenChange} classNames={{base: "before:bg-[#006038]"}} >
            <PopoverTrigger className="!opacity-100 !scale-100">
                <div className="relative lg:*:absolute lg:*:top-1/2 lg:*:left-1/2 lg:*:-translate-x-1/2 lg:*:-translate-y-1/2">
                    <div className={`${isOpen ? "scale-[1.4] " : ""}lg:rounded-full max-lg:hidden rounded-lg z-10 bg-[#DFDFDF] origin-center transition-all ease-out`} />
                    <div className={`${isOpen ? "scale-[1.2] " : ""}lg:rounded-full max-lg:hidden rounded-lg z-20 bg-[#7F7F7F] origin-center transition-all ease-out`} />
                    <div className={`bg-[#2D2D2D] lg:!scale-[1.05] font-semibold p-4 lg:rounded-full rounded-lg grid place-items-center z-40 text-white text-balance text-center`} >
                        {title}
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent className="p-4 bg-[#006038] z-[98] !max-w-[320px]">
                {text}
            </PopoverContent>
        </Popover>
    </div>
}
