"use client";
import { FiChevronDown } from "react-icons/fi";
import { useLocale, useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

export default function FAQ({ props }) {
    const locale = useLocale();
    const t = useTranslations("FAQ");
    const page = props;
    const [data, setData] = useState(null);

    const loadFaqData = async (locale) => {
        try {
            const response = await import(`../messages/${locale}/Faq.json`);
            return response.default || null;
        } catch (error) {
            console.error("Error loading FAQ data:", error);
            return null;
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const faqData = await loadFaqData(locale);
            setData(faqData);
        };
        fetchData();
    }, [locale]);

    if (!data) {
        return <p className="text-center">Loading...</p>;
    }

    return (data && data[page]) ? <section className="flex flex-col items-center justify-center gap-4 py-4 md:py-0 mt-5">
        <div className="container max-w-[1000px] block text-left justify-start items-center gap-3 sm:flex">
            <FiChevronDown className="text-[30px]" /><h2 className="text-15px lg:text-[25px] font-normal text-gray-300">{t("title")}</h2>
        </div>
        <div className="max-w-[1200px] mx-auto gap-4 p-3 pb-10">
            <div className="w-full lg:w-[960px] grid grid-cols-1 sm:grid-cols-2 gap-4">
                {data[page].map((item, index) => (
                    <details key={index} className="group rounded-lg bg-gray-50 p-6 [&_summary::-webkit-details-marker]:hidden">

                        <summary className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900">
                            <h3 className="font-medium text-[15px]">{item.question}</h3>
                            <span className="relative size-5 shrink-0">
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute inset-0 size-5 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" className="absolute inset-0 size-5 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            </span>
                        </summary>
                        <p className="mt-4 leading-relaxed !text-gray-700 faq_detail" dangerouslySetInnerHTML={{ __html: item.answer }} />
                    </details>
                ))}
            </div>
        </div>
    </section> : null;
}
